import C from "./Checkbox.module.css";

import { Button } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";

export function WFakeCheckBox({ isSelected, onClick }: { isSelected?: boolean; onClick?: (value: boolean) => void }) {
    const commonProps = {
        "data-selected": isSelected ? true : undefined,
    };

    function onChange() {
        if (onClick) {
            onClick(!isSelected);
        }
    }

    return (
        <Button className={cn(C.Checkbox, C.FakeCheckbox)} {...commonProps} onPress={onChange}>
            <div className={C.CheckIcon}>
                <svg viewBox="0 0 18 18" aria-hidden="true">
                    <polyline points="1 9 7 14 15 4" />
                </svg>
            </div>
        </Button>
    );
}
